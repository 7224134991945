/**
 * Set Cloudinary instance
 *
 * @tutorial https://cloudinary.com/documentation/javascript_integration
 */
import { Cloudinary } from '@cloudinary/url-gen';
import { CloudinaryMediaView } from '../types';

// Create instance
const cloudinary: Cloudinary = new Cloudinary({
    cloud: {
        cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_NAME ?? 'utmb-world',
    },
    url: {
        secure: true,
    },
});

/**
 * Convert a publicID string to a publicID cloudinaryMediaView object
 *
 * @param publicId Public ID to use to get the Cloudinary image
 * @param alt: Alternate text to use
 *
 * @returns The needed object to get the Cloudinary image
 */
export function pIdToCloudinary(publicId: string, alt: string): CloudinaryMediaView {
    return { publicId, alt } as CloudinaryMediaView;
}

export default cloudinary;
export { Cloudinary };
