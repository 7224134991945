// React
import { createContext, FC, ReactElement, useContext, useEffect, useState } from 'react';
// Modules
import { useRouter } from 'next/router';
// Contexts
import { useUser } from '@utmb/contexts/AuthContext';
// Interface
import { DashboardContextProps } from '@utmb/types/dashboard-context';
import { UserAccountTypeEnum } from '@utmb/types/enum/UserAccountTypeEnum';
import {
    DashboardMemberView,
    DashboardMemberInformationView,
    DashboardMemberPreferenceCenterView,
    DashboardMemberPreferencesView,
    DashboardOrganizerView,
    LangEnum,
} from '@utmb/types/index';
import OptinTenantEventView from '@utmb/types/view/OptinTenantEventView';
// Utils
import { fetchTheAPI } from '@utmb/utils/utmb';

const DashboardContext = createContext({} as DashboardContextProps);

export const DashboardProvider: FC = ({ children }): ReactElement => {
    const [isLoginMemberInProgress, setIsLoginMemberInProgress] = useState<boolean>(true);
    const [dashboardMember, setDashboardMember] = useState<DashboardMemberView | undefined>();
    const [dashboardOrganizer, setDashboardOrganizer] = useState<DashboardOrganizerView | undefined>();
    const [isAttached, setIsAttached] = useState<boolean | undefined>();
    const [listOptinEvents, setListOptinEvents] = useState<OptinTenantEventView[] | undefined>();
    const { asPath, push, locale } = useRouter();
    const user = useUser();
    const router = useRouter();

    const getMember = async (): Promise<DashboardMemberView | DashboardOrganizerView> => {
        const token = await user.getToken();

        const apiUrl = user.me?.accountType === UserAccountTypeEnum.ORGANIZER ? 'organizer' : 'member';

        return (await fetchTheAPI({
            isClient: true,
            path: `/users/dashboard/${apiUrl}`,
            params: { lang: locale! },
            bearer: token,
        })) as DashboardMemberView | DashboardOrganizerView;
    };

    const getOptinEvents = async (): Promise<OptinTenantEventView[]> => {
        return (await fetchTheAPI({
            isClient: true,
            path: '/users/dashboard/member/list-optin-events',
        })) as OptinTenantEventView[];
    };

    const refreshMember = async (): Promise<void> => {
        const { redirect_uri } = router.query;

        getMember().then((member) => {
            const isMyUTMBRegistrationPage = asPath.match(/^\/my-utmb\/registration\/information/);
            if (member) {
                if (user.me.accountType === UserAccountTypeEnum.ORGANIZER) {
                    setDashboardOrganizer(member as DashboardOrganizerView);
                } else {
                    setDashboardMember(member as DashboardMemberView);
                }

                // If in registration page, redirect to dashboard
                if (isMyUTMBRegistrationPage) {
                    if (redirect_uri) {
                        window.location.href = `${redirect_uri}`;
                    } else push('/my-utmb/my-dashboard');
                }
            } else {
                // If not in registration page, redirect to
                if (!isMyUTMBRegistrationPage) {
                    const lang = (locale ?? LangEnum.EN) === LangEnum.EN ? '' : `/${locale}`;
                    window.location.href = `https://utmb.world${lang}/my-utmb/registration/information`;
                }
            }

            setIsLoginMemberInProgress(false);
        });
        getOptinEvents().then((optinEvents) => setListOptinEvents(optinEvents));
    };

    useEffect(() => {
        const isMyUTMBPage = asPath.match(/^\/my-utmb\//);
        // If
        // - Login animation ended
        // - Login member animation ended
        // - Is on one of MyUTMB pages
        // - Member not already loaded
        if (!user.isLoginInProgress && isLoginMemberInProgress && !dashboardMember) {
            // if he's logged in
            if (user.isAuthenticated && user.me?.email) {
                // Get member
                refreshMember();
            } else {
                setIsLoginMemberInProgress(false);
                if (isMyUTMBPage) {
                    user.login();
                }
            }
        } else if (!user.isLoginInProgress && !isLoginMemberInProgress && !dashboardMember) {
            if (isMyUTMBPage) {
                user.login();
            }
        }
    }, [user.isLoginInProgress, asPath]);

    useEffect(() => {
        if (user?.me?.accountType) {
            const { accountType, profileUrl } = user.me;

            // Verify if
            // - it's a Member or Organizer
            // - is the logged Member user is attached to a runner page
            const isRunnerPageAttached = accountType === UserAccountTypeEnum.MEMBER && !!profileUrl;

            setIsAttached(isRunnerPageAttached);
        }
    }, [user]);

    /**
     * Set new information in member
     * @param {DashboardMemberInformationView} information
     */
    const updateDashboardMemberInformation = (information: DashboardMemberInformationView): void => {
        if (!dashboardMember) return;

        const newMemberInformation = Object.assign({}, dashboardMember.information, information);

        setDashboardMember({
            ...dashboardMember,
            information: newMemberInformation,
        });
    };

    /**
     * Set new preferences in member
     * @param {DashboardMemberPreferencesView} preferences
     */
    const updateDashboardMemberPreferences = (preferences: DashboardMemberPreferencesView): void => {
        if (!dashboardMember) return;

        const newMemberPreferences = Object.assign({}, dashboardMember.preferences, preferences);

        setDashboardMember({
            ...dashboardMember,
            preferences: newMemberPreferences,
        });
    };

    /**
     * Set new preference center in member
     * @param {DashboardMemberPreferenceCenterView} preferenceCenter
     */
    const updateDashboardMemberPreferenceCenter = (preferenceCenter: DashboardMemberPreferenceCenterView): void => {
        if (!dashboardMember) return;

        const newMemberPreferenceCenter = Object.assign({}, dashboardMember.preferenceCenter, preferenceCenter);

        setDashboardMember({
            ...dashboardMember,
            preferenceCenter: newMemberPreferenceCenter,
        });
    };

    /**
     * Set new information in organizer
     * @param {DashboardMemberInformationView} information
     */
    const updateDashboardOrganizerInformation = (information: DashboardOrganizerView): void => {
        if (!dashboardOrganizer) return;

        const newOrganizerInformation = Object.assign({}, dashboardOrganizer, information);

        setDashboardOrganizer(newOrganizerInformation);
    };

    return (
        <DashboardContext.Provider
            value={{
                isLoginMemberInProgress,
                dashboardMember,
                dashboardOrganizer,
                listOptinEvents,
                isAttached,
                setIsLoginMemberInProgress,
                updateDashboardMemberInformation,
                updateDashboardMemberPreferences,
                updateDashboardMemberPreferenceCenter,
                updateDashboardOrganizerInformation,
                refreshMember,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};

export const useDashboard = () => {
    const context = useContext(DashboardContext);
    if (context === undefined) {
        throw new Error(`useDashboard must be used within an DashboardProvider`);
    }
    return context;
};
