/**
 * Set Cloudinary methods and get Cloudinary image URL
 *
 * @tutorial https://cloudinary.com/documentation/javascript_integration
 */
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { artisticFilter, blur, colorize } from '@cloudinary/url-gen/actions/effect';
import { Conditional } from '@cloudinary/url-gen/actions/conditional';
import { scale, fill } from '@cloudinary/url-gen/actions/resize';
import { Transformation } from '@cloudinary/url-gen/transformation/Transformation';
import cloudinary from 'src/utils/cloudinary';
import { CloudinaryFiltersEnum, CloudinaryTransformEnum } from '@utmb/types/cloudinary-transform.enum';
import { trim } from '@cloudinary/url-gen/actions/reshape';
import { name } from '@cloudinary/url-gen/actions/namedTransformation';

const imageDefaultMaxWidth = 1920;

interface Props {
    getImage: (publicId: string, options?: GetImageOptionsProps) => string;
    getImageBlurred: (publicId: string, ratio: number) => string;
}

interface GetImageOptionsProps {
    maxWidth?: number;
    quality?: number | string;
    addOptions?: CloudinaryTransformEnum[];
    addFilters?: Record<string, any>;
}

export const useCloudinary = (): Props => {
    const getInstance = (publicId: string) => {
        // Instantiate a CloudinaryImage object for the image with public ID
        return cloudinary.image(publicId);
    };

    const getImage = (publicId: string, options?: GetImageOptionsProps): string => {
        const { maxWidth = imageDefaultMaxWidth, addOptions, addFilters } = options || {};

        const image = getInstance(publicId);
        // Apply some filters to reduce the image's weight
        image.quality(options?.quality || 'auto').format('auto');
        image.resize(fill().gravity(autoGravity()));
        if (addOptions?.includes(CloudinaryTransformEnum.TRIM)) image.reshape(trim());

        if (addOptions?.includes(CloudinaryTransformEnum.EPIC)) image.namedTransformation(name("epic"));

        // Apply filters
        if (addFilters) {
            if (CloudinaryFiltersEnum.COLORIZE_COLOR in addFilters) {
                const level = addFilters[CloudinaryFiltersEnum.COLORIZE_LEVEL] ?? 30;
                const color = addFilters[CloudinaryFiltersEnum.COLORIZE_COLOR];

                image.effect(artisticFilter('audrey')).effect(colorize().level(level).color(color));
            }
        }

        // Resize the image if superior than the given number of pixels
        const transformation = new Transformation();
        image.conditional(Conditional.ifCondition(`w > ${maxWidth}`, transformation.resize(scale().width(maxWidth))));

        // Return the image URL.
        return image.toURL();
    };

    const getImageBlurred = (publicId: string, ratio: number): string => {
        const image = getInstance(publicId);
        // Define size
        const width = 400; // Custom :)
        const height = ratio ? Math.ceil(width / ratio) : width;
        const transformation = new Transformation();
        // Blur it
        image
            // Resize to less weight if width >
            .conditional(Conditional.ifCondition(`w > ${width}`, transformation.resize(scale().width(width).height(height))))
            .effect(blur()); // Degrade quality by blurring
        // Return the image URL.
        return image.toURL();
    };

    return { getImage, getImageBlurred };
};
