import * as React from 'react';

// TODO: PSEUDO-CODE

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const makeHuman = () => ({
        firstName: 'Jane',
        lastName: 'Doe',
        birthDate: '1980',
    });
    return (
        <AuthContext.Provider
            value={{
                ...makeHuman(),
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useHuman = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error(`useHuman must be used within an AuthProvider`);
    }
    return context;
};
