import { PreferenceContext } from '@utmb/types/preference-context';
import { PreferenceContextProps } from '@utmb/types/preference-context-props';
import { UnitEnum } from '@utmb/types/unit-enum';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useGlobal } from './GlobalContext';
import { useTenancy } from './TenancyContext';

const PreferenceContext = createContext({} as PreferenceContextProps);

export const PreferenceProvider: FC = ({ children }) => {
    const cookies = new Cookies();

    const {
        global: { eventsTopBar },
    } = useGlobal();
    const { tenantId } = useTenancy();

    const cookieKey = 'utmbsf-prefs';

    const [preferences, setPreferences] = useState<PreferenceContext>();
    const defaultPreferences: PreferenceContext = {
        preferredUnit: UnitEnum.METRIC,
    };

    const storePreference = (newPreference: PreferenceContext): void => {
        setPreferences(newPreference);
        cookies.set(cookieKey, JSON.stringify(newPreference), {
            domain: 'utmb.world',
            sameSite: false,
            secure: true,
            path: '/',
            maxAge: 365 * 24 * 60 * 60,
        });
    };

    const setPreferredUnit = (unit: UnitEnum): void => {
        const newPreference: PreferenceContext = { ...preferences, preferredUnit: unit };
        storePreference(newPreference);
    };

    useEffect(() => {
        const pref = cookies.get(cookieKey) || {};

        //const navigatorLanguage = window?.navigator?.language;
        //if (navigatorLanguage === 'en-US' || navigatorLanguage === 'my-MM') defaultPreferences.preferredUnit = UnitEnum.IMPERIAL;
        const countryCode = eventsTopBar.find((e) => e.tenant === tenantId)?.countryCode?.toUpperCase() ?? '';
        if (countryCode === 'US' || countryCode === 'MM') defaultPreferences.preferredUnit = UnitEnum.IMPERIAL;
        storePreference({ ...defaultPreferences, ...pref });
    }, []);

    return (
        <PreferenceContext.Provider
            value={{
                setPreferredUnit,
                preferences: preferences || defaultPreferences,
            }}
        >
            {children}
        </PreferenceContext.Provider>
    );
};

export const usePreference = () => {
    const context = useContext(PreferenceContext);

    if (context === undefined) {
        throw new Error(`usePreference must be used within an PreferenceProvider`);
    }
    return context;
};
