import {
    doKeycloakLogin,
    doKeycloakLogout,
    doKeycloakRegister,
    getKeycloakLoginUrl,
    getKeycloakToken,
    initKeycloak,
    syncKeycloakToCookies,
} from '../features/sso/keycloak';

import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { UserMeView } from '@utmb/types/index';
import { fetchTheAPI } from '@utmb/utils/utmb';
import { useGlobal } from '@utmb/contexts/GlobalContext';
import JwtView from '@utmb/types/type/JwtView';
import jwtDecode from 'jwt-decode';

interface AuthContextProps {
    isAuthenticated: null | boolean;
    isLoginInProgress: boolean;
    me: UserMeView;
    jwt: JwtView | null;
    login: () => void;
    logout: () => void;
    register: () => void;
    getToken: () => Promise<string | undefined>;
    refreshMe: () => Promise<void>;
    getLoginUrl: (redirectUri: string) => string;
}

/*const keycloakLocaleSubpath: { [key: string]: string } = {
    en: '',
    //zh: '/zh-CN',
};

export const getLocaleSubPath = (locale: string | undefined): string => {
    return locale ? keycloakLocaleSubpath[locale] : `/${locale}`;
};
*/

const AuthContext = React.createContext({} as AuthContextProps);

export const AuthProvider: FC = ({ children }) => {
    const [isAuthenticated, setAuthenticated] = useState<null | boolean>(null);
    const [isLoginInProgress, setIsLoginInProgress] = useState<boolean>(true);

    const { global } = useGlobal();
    const { push } = useRouter();
    const [me, setMe] = useState<any>();
    const [jwt, setJwt] = useState<JwtView | null>(null);

    const { locale, asPath } = useRouter();

    useEffect(() => {
        const init = async () => {
            const auth = await initKeycloak();

            const isAuthenticated = auth?.isAuthenticated || false;
            setAuthenticated(isAuthenticated);
            setIsLoginInProgress(isAuthenticated);
        };
        init().then();
    }, []);

    const getMe = async () => {
        const token = await getKeycloakToken();

        setJwt(jwtDecode(token));

        const me = await fetchTheAPI({
            isClient: true,
            path: `/users/me`,
            bearer: token,
        });

        setMe(me);
        setIsLoginInProgress(false);
    };

    useEffect(() => {
        if (isAuthenticated) {
            // Sync keycloak access token into cookies only for the world series website
            if (global?.event?.isWS) {
                syncKeycloakToCookies();
            }

            getMe().then();
        }
    }, [isAuthenticated]);

    const login = async () => {
        doKeycloakLogin(locale, getRedirectUri());
    };

    const logout = async () => {
        if (global?.event?.isWS) {
            doKeycloakLogout();
        } else {
            push(`${process.env.NEXT_PUBLIC_WORLD_SERIES_URL}/logout`);
        }
    };

    const refreshMe = async () => getMe();

    const register = async () => {
        doKeycloakRegister(locale, getRedirectUri());
    };

    const getToken = async (): Promise<string | undefined> => {
        return isAuthenticated ? getKeycloakToken() : undefined;
    };

    const getRedirectUri = (): string => {
        const subPath = !locale || locale === 'en' ? '' : `/${locale}`;

        if ((asPath && asPath !== '/') || !global?.event?.isWS) {
            return `${subPath}/${asPath}/`.split('?')[0];
        } else {
            return `${subPath}/my-utmb/my-dashboard`;
        }
    };

    const getLoginUrl = (redirectUri: string): string => {
        return getKeycloakLoginUrl(locale, redirectUri);
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                isLoginInProgress,
                me,
                jwt,
                login,
                logout,
                register,
                getLoginUrl,
                getToken,
                refreshMe,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useUser = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error(`useUser must be used within an AuthProvider`);
    }
    return context;
};
