import React, { FC } from 'react';

interface TenancyContextProps {
    tenantId: string;
}

const TenancyContext = React.createContext({} as TenancyContextProps);

interface TenancyProviderProps {
    tenantId: string;
}

export const TenancyProvider: FC<TenancyProviderProps> = ({ children, tenantId }) => {
    return (
        <TenancyContext.Provider
            value={{
                tenantId,
            }}
        >
            {children}
        </TenancyContext.Provider>
    );
};

export const useTenancy = () => {
    const context = React.useContext(TenancyContext);
    if (context === undefined) {
        throw new Error(`useTenancy must be used within an TenancyProvider`);
    }
    return context;
};
