import { ReactElement, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import { GTM_ID } from '../../constants/gtm';

export const Gtm = (): ReactElement => {
    const { locale } = useRouter();
    const isDev = process.env.NODE_ENV === 'development';
    useEffect(() => {
        if (!isDev) {
            TagManager.initialize({
                gtmId: GTM_ID,
                dataLayer: {
                    language: locale,
                },
            });
        }
    });
    return <></>;
};
