import { useGlobal } from '@utmb/contexts/GlobalContext';
import { useRouter } from 'next/router';
import { ReactElement, useState } from 'react';
import { Font } from '@utmb/design-system/text/font';
import { useTranslation } from 'next-i18next';
import { Icon } from '@utmb/design-system/icons/Icon';
import { IconsEnum } from '@utmb/types/icons';

// Styles
import styles from './sticky-player.module.scss';

export const StickyPlayer = (): ReactElement | null => {
    const { t } = useTranslation();
    const router = useRouter();
    const {
        global: { event },
    } = useGlobal();
    const [isStickyVisible, setStickyVisible] = useState(!!event?.stickyWebTVLive);

    return (
        <>
            {event?.stickyWebTVLive?.trim() && isStickyVisible && (
                <a href={`https://live.utmb.world/${router.locale}/${event.tenantUtmbLive}`} className={styles.sticky_player}>
                    <Font.OxaniumRegular desktop="15" mobile="12" className={styles.sticky_title} as="div">
                        {t('common.stickyLiveTitle')} <Icon variant={IconsEnum.LIVE} />
                        <button
                            onClick={(event) => {
                                event.preventDefault();
                                setStickyVisible(false);
                            }}
                            className={styles.close}
                        >
                            <Icon variant={IconsEnum.CLOSE} />
                        </button>
                    </Font.OxaniumRegular>
                    <iframe
                        src={event.stickyWebTVLive}
                        title="stickyWebTV"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                        allowFullScreen
                    ></iframe>
                </a>
            )}
        </>
    );
};
