import { createContext, FC, useContext } from 'react';
import { GlobalContextProps } from '@utmb/types/global-context';

const GlobalContext = createContext({} as GlobalContextProps);

export const GlobalProvider: FC<GlobalContextProps> = ({ children, global }) => {
    return (
        <GlobalContext.Provider
            value={{
                global,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export const useGlobal = () => {
    const context = useContext(GlobalContext);

    if (context === undefined) {
        throw new Error(`useGlobal must be used within an GlobalProvider`);
    }
    return context;
};
