/**
 * Enum used to determin the color a component needs to use.
 */
export enum CloudinaryTransformEnum {
    TRIM = 'trim',
    FILTER = 'filter',
    EPIC = 'epic',
}

/**
 * Enum used to determin filters to apply to an image.
 */
export enum CloudinaryFiltersEnum {
    COLORIZE_LEVEL = 'colorize_level',
    COLORIZE_COLOR = 'colorize_color',
}
