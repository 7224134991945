import { SSRProvider } from '@react-aria/ssr';
import { I18nProvider } from '@react-aria/i18n';
import { ParallaxProvider } from 'react-scroll-parallax';
import { OverlayProvider } from '@react-aria/overlays';
import { AuthProvider } from './auth-provider';

const AppProviders = ({ initialLocale, children }) => (
    <I18nProvider locale={initialLocale}>
        <OverlayProvider> {children} </OverlayProvider>
    </I18nProvider>
);

const PageProviders = ({ children }) => {
    return <SSRProvider>{children}</SSRProvider>;
};

const PageProvidersAuth = combineProviders(SSRProvider, AuthProvider);

const PageProvidersParallax = combineProviders(SSRProvider, ParallaxProvider);

export { AppProviders, PageProviders, PageProvidersAuth, PageProvidersParallax };

function combineProviders(...providers) {
    return ({ children }) => providers.reduce((prev, CurrentProvider) => <CurrentProvider>{prev}</CurrentProvider>, children);
}
