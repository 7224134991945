const fallbackLng = {
    default: ['en'],
    zh: ['zh-Hans', 'en'],
    'zh-CN': ['zh-Hans', 'en'],
    'zh-HK': ['zh-Hant', 'zh-Hans', 'en'],
    'zh-SG': ['zh-Hans', 'zh-Hant', 'en'],
    'zh-TW': ['zh-Hant', 'zh-Hans', 'en'],
};

module.exports = {
    i18n: {
        defaultLocale: 'en',
        locales: [
            'en', 
            'fr', 
            'es', 
            'ca', 
            'it', 
            'hr', 
            'ja',
            'ko',
            'de', 
            'sl', 
            'sv', 
            'th',
            'tr',
            'oc', 
            'pt',
            'zh-Hant', 
            'zh-Hans',
        ],
        defaultNS: ['common'],
        localeDetection: true,
        fallbackLng,
    },
};
