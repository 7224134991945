// React
import { createContext, FC, useContext, useState } from 'react';
// Next
import { useRouter } from 'next/router';
// Interfaces
import FullCountryByContinentView from '@utmb/types/view/FullCountryByContinentView';
import CountryView from '@utmb/types/view/CountryView';
import { LangEnum } from '@utmb/types/enum/LangEnum';
// Utils
import { fetchTheAPI } from '@utmb/utils/utmb';

interface CountryContextProps {
    countries: CountryView[];
    initCountries: () => void;
    fullCountriesByContinent: FullCountryByContinentView[];
    initFullCountriesByContinent: () => void;
}

const CountryContext = createContext({} as CountryContextProps);

export const CountryProvider: FC = (props) => {
    const { children } = props;

    const { locale } = useRouter();

    const [countries, setCountries] = useState<CountryView[]>([]);
    const [isCountriesFetched, setIsCountriesFetched] = useState<boolean>(false);
    const [fullCountriesByContinent, setFullCountriesByContinent] = useState<FullCountryByContinentView[]>([]);
    const [isFullCountriesByContinentFetched, setIsFullCountriesByContinentFetched] = useState<boolean>(false);

    const fetchCountriesByContinent = async (): Promise<void> => {
        const fullCountriesByContinent = (await fetchTheAPI({
            isClient: true,
            path: `/countries/full-by-continent`,
            params: { lang: locale! },
        })) as FullCountryByContinentView[];

        if (fullCountriesByContinent.length > 0) {
            setFullCountriesByContinent(fullCountriesByContinent);
            setIsFullCountriesByContinentFetched(true);
        }
    };

    const fetchCountries = async (): Promise<void> => {
        const countries = (await fetchTheAPI({
            isClient: true,
            path: '/countries',
            params: { lang: locale as LangEnum },
        })) as CountryView[];

        if (countries.length > 0) {
            setCountries(countries);
            setIsCountriesFetched(true);
        }
    };

    const initFullCountriesByContinent = () => {
        if (!isFullCountriesByContinentFetched) {
            fetchCountriesByContinent().then();
        }
    };

    const initCountries = () => {
        if (!isCountriesFetched) {
            fetchCountries().then();
        }
    };

    return (
        <CountryContext.Provider
            value={{
                countries,
                initCountries,
                fullCountriesByContinent,
                initFullCountriesByContinent,
            }}
        >
            {children}
        </CountryContext.Provider>
    );
};

export const useCountry = () => {
    const context = useContext(CountryContext);
    if (context === undefined) {
        throw new Error(`useCountry must be used within an CountryContextProvider`);
    }
    return context;
};
