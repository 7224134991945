import { IncomingMessage } from 'http';

/**
 * Custom Http header-based tenant identification
 *
 * @param {string} TENANCY_HTTP_HEADER
 */
const TENANCY_HTTP_HEADER = 'x-tenant-id';

/**
 * Default tenant identification
 *
 * @param {string} TENANCY_DEFAULT
 */
const TENANCY_DEFAULT = process.env.NEXT_PUBLIC_DEFAULT_TENANCY || 'utmb';

/**
 * Get current tenant ID from headers
 *
 * @param {IncomingMessage} req
 *
 * @returns {string}
 */
const getTenantId = (req: IncomingMessage): string => {
    return (req?.headers[TENANCY_HTTP_HEADER] ?? TENANCY_DEFAULT) as string;
};

/**
 * Check if responseAPI is valid and returns data
 *
 * @param {any} responseAPI
 *
 * @returns {boolean}
 */
const is404 = (responseAPI: any): boolean => {
    return !responseAPI || responseAPI.statusCode === 404;
};

enum FetchMethodsEnum {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

export type FetchAPIType = {
    isClient?: boolean;
    req?: IncomingMessage;
    tenantId?: string;
    path: string;
    slug?: string;
    method?: FetchMethodsEnum;
    params?: Record<string, string>;
    body?: Record<string, unknown>;
    bearer?: string;
};

export async function fetchTheAPI({ isClient, req, tenantId, path, slug, method, params, body, bearer }: FetchAPIType): Promise<unknown> {
    return _fetch(Boolean(isClient), path, tenantId ?? (req ? getTenantId(req) : TENANCY_DEFAULT), slug, params, method, body, {
        Authorization: `Bearer ${bearer}`,
    });
}

/**
 * Fetch method without slug
 *
 * @param {IncomingMessage} req
 * @param {string} path
 * @param {Record<string, string>} params
 * @param {FetchMethodsEnum} method
 * @param {Record<string, unknown>} body
 *
 * @returns {Promise<any>}
 */
export async function fetchAPI(
    req: IncomingMessage,
    path: string,
    params?: Record<string, string>,
    method?: FetchMethodsEnum,
    body?: Record<string, unknown>
): Promise<any>;

/**
 * Fetch method with default headers
 *
 * @param {IncomingMessage} req
 * @param {string} path
 * @param {string} slug
 * @param {Record<string, string>} params
 * @param {FetchMethodsEnum} method
 * @param {Record<string, unknown>} body
 *
 * @returns {Promise<any>}
 */
export async function fetchAPI(
    req: IncomingMessage,
    path: string,
    slug: string,
    params?: Record<string, string>,
    method?: FetchMethodsEnum,
    body?: Record<string, unknown>
): Promise<any>;

export async function fetchAPI(
    req: IncomingMessage,
    path: string,
    arg3?: string | Record<string, string>,
    arg4?: FetchMethodsEnum | Record<string, string>,
    arg5?: FetchMethodsEnum | Record<string, unknown>,
    arg6?: Record<string, unknown>
): Promise<any> {
    const haveSlug = typeof arg3 === 'string';

    const slug = haveSlug ? (arg3 as string) : undefined;
    const params = (haveSlug ? arg4 : arg3) as Record<string, string>;
    const method = ((haveSlug ? arg5 : arg4) as FetchMethodsEnum) ?? FetchMethodsEnum.GET;
    const body = (haveSlug ? arg6 : arg5) as Record<string, unknown>;

    return _fetch(false, path, getTenantId(req), slug, params, method, body);
}

/**
 * Fetch method from client with default headers
 *
 * @param {tenantId} tenantId
 * @param {string} path
 * @param {string} slug
 * @param {Record<string, string>} params
 * @param {FetchMethodsEnum} method
 * @param {Record<string, unknown>} body
 *
 * @returns {Promise<any>}
 */
export async function fetchClientAPI(
    tenantId: string,
    path: string,
    slug?: string,
    method?: FetchMethodsEnum,
    params?: Record<string, string>,
    body?: Record<string, unknown>
): Promise<any>;

/**
 * Fetch method from client without slug
 *
 * @param {tenantId} tenantId
 * @param {string} path
 * @param {Record<string, string>} params
 * @param {FetchMethodsEnum} method
 * @param {Record<string, unknown>} body
 *
 * @returns {Promise<any>}
 */
export async function fetchClientAPI(
    tenantId: string,
    path: string,
    params?: Record<string, string>,
    method?: FetchMethodsEnum,
    body?: Record<string, unknown>
): Promise<any>;

export async function fetchClientAPI(
    tenantId: string,
    path: string,
    arg2?: string | Record<string, string>,
    arg3?: FetchMethodsEnum | Record<string, string>,
    arg4?: Record<string, string> | Record<string, unknown>,
    arg5?: Record<string, unknown>
): Promise<any> {
    const haveSlug = typeof arg2 === 'string';

    const slug = haveSlug ? (arg2 as string) : undefined;
    const params = (haveSlug ? arg3 : arg2) as Record<string, string>;
    const method = ((haveSlug ? arg4 : arg3) as FetchMethodsEnum) ?? FetchMethodsEnum.GET;
    const body = (haveSlug ? arg5 : arg4) as Record<string, unknown>;

    return _fetch(true, path, tenantId, slug, params, method, body);
}

/**
 * Made the request.
 *
 * @param {boolean} isClient
 * @param {string} path
 * @param {string} tenantId
 * @param {string} slug
 * @param {Record<string, string>} params
 * @param {FetchMethodsEnum} method
 * @param {Record<string, unknown>} body
 * @param {{[K in string]: string}} headers
 * @returns
 */
async function _fetch(
    isClient: boolean,
    path: string,
    tenantId: string,
    slug?: string,
    params?: Record<string, string>,
    method?: FetchMethodsEnum,
    body?: Record<string, unknown>,
    headers?: { [K in string]: string }
): Promise<any> {
    const options: any = {
        method,
        headers: { 'content-type': 'application/json', [TENANCY_HTTP_HEADER]: tenantId, ...headers },
    };

    if (slug) {
        slug = encodeURIComponent(slug);
    }

    const pathname = `${path}/${slug ?? ''}`.replace(/\/$/, '');

    const query = params ? `?${new URLSearchParams(params).toString()}` : '';

    if (method !== FetchMethodsEnum.GET && body) {
        options.body = JSON.stringify(body);
    }

    const baseUrl = isClient ? process.env.NEXT_PUBLIC_FRONTEND_API_URL : process.env.NEXT_PUBLIC_API_URL;

    return await fetch(`${baseUrl}${pathname}${query}`, options)
        .then((response) => response.json())
        .then((json) => !is404(json) && json)
        .catch(() => null);
}

export async function downloadFromTheAPI({ isClient, req, tenantId, path, method, params, body, bearer }: FetchAPIType): Promise<Blob | null> {
    /*return _fetch(Boolean(isClient), path, tenantId ?? (req ? getTenantId(req) : TENANCY_DEFAULT), slug, params, method, body, {
        Authorization: `Bearer ${bearer}`,
    });*/
    const tenant = tenantId ?? (req ? getTenantId(req) : TENANCY_DEFAULT);
    const options: any = {
        method,
        headers: { 'content-type': 'application/json', [TENANCY_HTTP_HEADER]: tenant, Authorization: `Bearer ${bearer}` },
    };

    const query = params ? `?${new URLSearchParams(params).toString()}` : '';

    if (method !== FetchMethodsEnum.GET && body) {
        options.body = JSON.stringify(body);
    }

    const baseUrl = isClient ? process.env.NEXT_PUBLIC_FRONTEND_API_URL : process.env.NEXT_PUBLIC_API_URL;

    return await fetch(`${baseUrl}${path}${query}`, options)
        .then((response) => response.blob())
        .catch(() => null);
}

export { TENANCY_DEFAULT, TENANCY_HTTP_HEADER, getTenantId, is404, FetchMethodsEnum };
