import { KeycloakInitOptions, KeycloakInstance } from 'keycloak-js';
import { Authorization } from '@utmb/types/keycloak';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Keycloak = typeof window !== 'undefined' ? require('keycloak-js') : null;

export const ACCESS_TOKEN_COOKIE_NAME = 'access_token';

let _kc: KeycloakInstance | null = null;

const isDebug = process.env.NEXT_PUBLIC_KEYCLOAK_DEBUG === '1';

const config = {
    realm: process.env.NEXT_PUBLIC_KEYCLOAK_REALM,
    url: process.env.NEXT_PUBLIC_KEYCLOAK_URL,
    clientId: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID,
    'enable-cors': false,
};

const init: KeycloakInitOptions = {
    onLoad: 'check-sso',
    checkLoginIframe: false,
    silentCheckSsoRedirectUri: typeof window !== 'undefined' ? `${window.location.origin}/sso/silent-check-sso.html` : ``,
    promiseType: 'native',
    pkceMethod: 'S256',
} as KeycloakInitOptions;

export const initKeycloak = async (): Promise<Authorization | null | undefined> => {
    if (typeof window !== 'undefined') {
        if (isDebug) console.log('DEBUG Keycloak config : ', config);
        _kc = Keycloak(config);

        if (isDebug) console.log('DEBUG Keycloak init : ', init);

        return (
            _kc &&
            _kc
                .init(init)
                .then((authenticated) => {
                    if (isDebug) console.log('DEBUG Keycloak _kc : ', _kc);

                    return {
                        isAuthenticated: authenticated,
                        tokenParsed: _kc?.tokenParsed,
                        token: _kc?.token,
                    } as Authorization;
                })
                .catch((err) => {
                    console.error('Error in initKeycloak : init %s, config %s. Error :', JSON.stringify(init), JSON.stringify(config), err);
                    throw err;
                })
        );
    }
};

const buildRedirectUri = (redirectUri: string) => {
    if (redirectUri.startsWith('https://') || redirectUri.startsWith('http://')) return redirectUri;
    return `${window.origin}${redirectUri}`;
};

export const doKeycloakLogin = (locale = 'en', redirectUri = '/') => {
    _kc?.login({ locale, redirectUri: buildRedirectUri(redirectUri) });
};

export const getKeycloakLoginUrl = (locale = 'en', redirectUri = '/'): string => {
    return _kc?.createLoginUrl({ locale, redirectUri: buildRedirectUri(redirectUri) }) ?? '';
};

export const doKeycloakLogout = (redirectUri = '/') => {
    cookies.remove(ACCESS_TOKEN_COOKIE_NAME, {
        path: '/',
        domain: window.location.hostname,
    });
    _kc?.logout({ redirectUri: buildRedirectUri(redirectUri) });
};

export const doKeycloakRegister = (locale = 'en', redirectUri = '/') => {
    _kc?.register({ locale, redirectUri: buildRedirectUri(redirectUri) });
};

export const getKeycloakToken = async (): Promise<string> => {
    await new Promise((resolve, reject) => {
        _kc?.updateToken(5)
            .then(() => {
                resolve(null);
            })
            .catch(() => {
                reject();
            });
    });

    return _kc?.token || '';
};

export const syncKeycloakToCookies = () => {
    syncToken();
    setInterval(() => _kc?.updateToken(60), 60 * 1000);
    if (_kc) {
        _kc.onAuthRefreshSuccess = () => {
            syncToken();
        };
    }
};

const syncToken = () => {
    const token = _kc?.token;
    if (token) {
        const expires = new Date((_kc?.tokenParsed?.exp ?? 0) * 1000);
        if (isDebug) console.log('DEBUG Keycloak syncing access token to cookie with expires : ', expires);
        cookies.set(ACCESS_TOKEN_COOKIE_NAME, _kc?.token, {
            path: '/',
            domain: window.location.hostname,
            expires,
        });
    }
};
